import { getCookie } from 'cookies-next';

import { SocialLoginProvider, LoginMessageEnum, LoginData } from '../types';
import { clientAxios } from '../api';

export const generateSocialProviderName = (provider: SocialLoginProvider) => {
  switch (provider) {
    case 'google':
      return '구글';
    case 'facebook':
      return '페이스북';
    default:
      return '';
  }
};

export const generateProviderBackendName = (provider: SocialLoginProvider) => {
  return provider === 'google' ? 'google-oauth2' : provider;
};

export const getAnonymousClientUuid = () => getCookie('anonymous_client_uuid');
export const deleteAnonymousClientUuid = async () => {
  await clientAxios.delete('/cookie/anonymous_client_uuid');
};

export const fetchLoginApi = async (
  id: string,
  password: string,
  remember: boolean,
  anonymousClientUuid: string | undefined,
  isAppliedDelete: boolean
) => {
  return clientAxios.post<{ ok: boolean; data: LoginData }>('/loginApi', {
    id,
    password,
    remember,
    anonymousClientUuid,
    isAppliedDelete,
  });
};

export const validateLoginResponse = (msg: LoginMessageEnum) => {
  const validLoginMessages: LoginMessageEnum[] = [
    LoginMessageEnum.CAN_LOGIN,
    LoginMessageEnum.NOT_ACTIVE_BY_EMAIL,
  ];

  return validLoginMessages.includes(msg);
};
