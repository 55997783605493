const PATH = Object.freeze({
  // authorized
  HOME: '/settings',
  PASSWORD_CHANGE: '/settings/password/change',
  WITHDRAWAL: '/settings/withdrawal',
  ACCOUNT: '/settings/account',
  SIGNUP_EMAIL_VERIFY: '/signup/verify',
  SIGNUP_COMPLETE: '/signup/complete',
  // unauthorized
  SIGNUP: '/signup',
  LOGIN: '/login',
  PASSWORD_FIND: '/settings/password/find',
  PASSWORD_RESET: '/settings/password/reset',
  SIGNUP_SOCIAL: '/signup/social',
  // common
  TERMS_PRIVACY: '/terms/privacy',
  TERMS_SERVICE: '/terms/service',
  TERMS_YOUTH_PROTECTION: '/terms/youth-protection',
  EMAIL_ACTIVATE: '/activate',
  EMAIL_UNSUBSCRIBE: '/unsubscribe',
  // other
  YOZMIT: process.env.NEXT_PUBLIC_YOZMIT_URL as string,
  JOBS: process.env.NEXT_PUBLIC_JOBS_URL as string,
  WISHKET: process.env.NEXT_PUBLIC_WISHKET_URL as string,
  // popup
  SOCIAL_LOGIN_POPUP: '/popup',
});

export default PATH;
