import { Session } from 'next-auth';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export type SocialLoginProvider = 'google' | 'facebook';

export enum LoginMessageEnum {
  CAN_LOGIN = 'can_login',
  NEED_SIGNUP = 'need_signup',
  NEED_LINK = 'need_link',
  PARTNER_HAS_PROJECT = 'partner_has_project',
  NOT_ACTIVE_BY_EMAIL = 'not_active_by_email',
}

export interface SocialSession extends Session {
  account: {
    access_token: string;
    provider: SocialLoginProvider;
    providerAccountId: string;
  };
}

export interface LoginData {
  msg: LoginMessageEnum;
  email: string;
  access?: string;
  refresh?: string;
  username?: string;
}

export interface SocialLoginData {
  msg: LoginMessageEnum;
  email: string;
  social_account_id: number;
  access?: string;
  refresh?: string;
  username?: string;
  providerAccessToken: string;
}
