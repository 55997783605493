import crypto from 'crypto-js';

export const encrypt = (value: string) => {
  const secretKey = process.env.CRYPTO_SECRET_KEY as string;

  return crypto.AES.encrypt(value, secretKey).toString();
};

export const decrypt = (value: string) => {
  const secretKey = process.env.CRYPTO_SECRET_KEY as string;

  return crypto.AES.decrypt(value, secretKey).toString(crypto.enc.Utf8);
};
