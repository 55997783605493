export const parseQueryString = (queryString: string) => {
  if (!queryString) return {};

  return queryString.split('&').reduce(
    (params, param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
      return params;
    },
    {} as { [key: string]: string }
  );
};
