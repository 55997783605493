import axios, { InternalAxiosRequestConfig } from 'axios';

import ServerAuth from '../auth/ServerAuth';

const serverAxios = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_APP_BASE_API_URL}`,
});

const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const accessToken = await ServerAuth.getAccessToken();

  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return Promise.resolve(config);
};

serverAxios.interceptors.request.use(onRequest);

export default serverAxios;
