import jwt, {
  Jwt as JwtType,
  JwtPayload as JwtPayloadType,
} from 'jsonwebtoken';

export const decodeJwt = (token: string) => {
  try {
    const decodedToken = jwt.decode(token, { complete: true });

    return decodedToken;
  } catch (error) {
    return null;
  }
};

export type { JwtType, JwtPayloadType };
