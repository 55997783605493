const getKoreanTimeString = (createdTime: string) => {
  const newDate = new Date(createdTime);
  const item = new Intl.DateTimeFormat('ko-KR', {
    timeZone: 'Asia/Seoul',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(newDate);
  const [year, month, date, half, time] = item.replaceAll('.', '').split(' ');
  const formattedMonth = month[0] == '0' ? month.slice(1) : month;
  const formattedDate = date[0] === '0' ? date.slice(1) : date;
  const day = new Date(Number(year), Number(month) - 1, Number(date)).getDay();

  return {
    date: `${year}년 ${formattedMonth}월 ${formattedDate}일`,
    time: `${half} ${time}`,
    day,
  };
};

export default getKoreanTimeString;
