import { JwtUtils } from '../auth';

const decodeAccessToken = (token: string) => {
  const decodeToken = JwtUtils.decodeJwt(token) as JwtUtils.JwtType | null;

  if (!decodeToken || !decodeToken.payload) return null;

  const {
    email,
    is_verification_required,
    user_id,
    username,
    first_signup_service,
  } = decodeToken.payload as JwtUtils.JwtPayloadType;

  return {
    email,
    is_verification_required,
    user_id,
    username,
    first_signup_service,
  };
};

export default decodeAccessToken;
