import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

const clientAxios = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_APP_BASE_URL}/api`,
  headers: {},
});

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError | Error): Promise<AxiosError> => {
  return Promise.reject(error);
};

clientAxios.interceptors.request.use(onRequest);
clientAxios.interceptors.response.use(onResponse, onResponseError);

export default clientAxios;
