'use client';

import { getCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { clientAxios } from '../api';

import Auth from './Auth';

const A_WEEK = 60 * 60 * 24 * 7;

class ClientAuth {
  static getCookie(name: string) {
    return getCookie(name);
  }

  static setCookie(name: string, value: string, options: OptionsType) {
    setCookie(name, value, options);
  }

  static async deleteCookies() {
    await Promise.all([
      clientAxios.delete('/cookie/WART'),
      clientAxios.delete('/cookie/wsessionid'),
      clientAxios.delete('/cookie/csrftoken'),
    ]);
  }

  static async getAccessToken(): Promise<string | null> {
    const { data } = await clientAxios.get('/cookie/wsessionid');

    const wSessionId = data.data;
    const hasWsessionid = !!wSessionId;

    if (!hasWsessionid) return null;

    const WART = ClientAuth.getCookie('WART') as string | null;
    const hasWART = !!WART;

    const encodedTokens = WART;

    if (hasWsessionid && !hasWART) {
      try {
        const { data } = await clientAxios.post('/legacyRefreshApi', {
          wSessionId,
        });

        if (data.ok) {
          await ClientAuth.authenticate(data.data.access, data.data.refresh);

          return data.data.access;
        } else {
          return null;
        }
      } catch {
        console.log('ClientAuth legacyRefreshApi error');

        return null;
      }
    }

    const { accessTokenExpiredTime, refreshTokenExpiredTime } =
      Auth.getExpiredTime(encodedTokens);

    if (accessTokenExpiredTime === null || refreshTokenExpiredTime === null) {
      return null;
    }

    if (Date.now() < accessTokenExpiredTime) {
      const tokens = Auth.decodingToken(encodedTokens);

      if (!tokens) return null;

      return tokens.accessToken;
    }

    if (Date.now() < refreshTokenExpiredTime) {
      const refreshToken = ClientAuth.getRefreshToken();
      if (!refreshToken) return null;

      const newAccessToken = await Auth.refreshAccessToken(refreshToken);
      if (!newAccessToken) return null;

      ClientAuth.authenticate(newAccessToken, refreshToken);

      return newAccessToken;
    }

    await ClientAuth.deAuthenticate();

    return null;
  }

  static authenticate(accessToken: string, refreshToken: string) {
    const encodedTokens = Auth.encodingToken({ accessToken, refreshToken });
    ClientAuth.setTokenInCookie(encodedTokens);
  }

  static async deAuthenticate() {
    await ClientAuth.deleteCookies();
  }

  static getRefreshToken() {
    const encodedTokens = ClientAuth.getCookie('WART');
    if (!encodedTokens) return null;

    const tokens = Auth.decodingToken(encodedTokens);

    if (!tokens) return null;

    return tokens.refreshToken;
  }

  static setTokenInCookie(token: string) {
    const domain = Auth.getDomain();
    ClientAuth.setCookie('WART', token, {
      path: '/',
      maxAge: A_WEEK,
      domain,
    });
  }
}

export default ClientAuth;
