import { clientAxios } from '../api';
import { Tokens } from '../types';

import { decrypt, encrypt } from './crypto';
import { JwtType, JwtPayloadType, decodeJwt } from './jwt';

const A_SECOND = 1000;

class Auth {
  static encodingToken({ accessToken, refreshToken }: Tokens) {
    const tokens = { accessToken, refreshToken };
    const stringifiedTokens = JSON.stringify(tokens);

    return encrypt(stringifiedTokens);
  }

  static decodingToken(encodedTokens: string | null): Tokens | null {
    if (!encodedTokens) return null;

    try {
      const decodedTokens = decrypt(encodedTokens);
      return JSON.parse(decodedTokens);
    } catch (error) {
      return null;
    }
  }

  static getExpiredTime(encodedTokens: string | null) {
    if (!encodedTokens) {
      return { accessTokenExpiredTime: null, refreshTokenExpiredTime: null };
    }

    const tokens = Auth.decodingToken(encodedTokens);

    if (!tokens) {
      return { accessTokenExpiredTime: null, refreshTokenExpiredTime: null };
    }

    const getRemainExpiredTime = (token: string): number => {
      const { payload } = decodeJwt(token) as JwtType;
      const { exp } = payload as JwtPayloadType;

      return exp ? exp * A_SECOND : 0;
    };
    const accessTokenExpiredTime = getRemainExpiredTime(tokens.accessToken);
    const refreshTokenExpiredTime = getRemainExpiredTime(tokens.refreshToken);

    return { accessTokenExpiredTime, refreshTokenExpiredTime };
  }

  static getDomain() {
    const stage = process.env.NEXT_PUBLIC_APP_STAGE;
    if (stage === 'prod') return '.wishket.com';
    if (stage === 'stag') return '.wishdev.net';
    if (stage === 'dev') return '.wishdev.net';

    return 'localhost';
  }

  static async refreshAccessToken(refreshToken: string | null) {
    if (!refreshToken) return null;

    try {
      const { data } = await clientAxios.post('/refreshAccessTokenApi', {
        refreshToken,
      });

      return data.data.access;
    } catch (e) {
      return null;
    }
  }
}

export default Auth;
