const maskId = (id: string) => {
  if (!id || id.length <= 2) return id;

  const firstTwoChars = id?.slice(0, 2);
  const maskedChars = '*'.repeat(5);

  return firstTwoChars + maskedChars;
};

const maskEmail = (email: string) => {
  if (!email || !email.includes('@')) return email;

  const [accountId, companyId] = email.split('@');

  if (accountId?.length <= 2) return email;

  const firstTwoChars = accountId?.slice(0, 2);
  const maskedChars = '*'.repeat(5);

  return firstTwoChars + maskedChars + '@' + companyId;
};

export { maskId, maskEmail };
