interface SocialLinkData {
  provider: string;
  type: 'link' | 'unlink';
}

export class SocialLinkStorage {
  private static hasWindow = () => !!window && !!window.localStorage;

  private static validateWindow(operation: string) {
    if (!this.hasWindow()) {
      throw new Error(
        `window 객체가 없어 SocialLinkStorage.${operation}에 실패했습니다.`
      );
    }
  }

  private static handleError(operation: string, error: unknown) {
    console.error(
      `SocialLinkStorage.${operation}에 실패했습니다.`,
      JSON.stringify(error)
    );

    return null;
  }

  static get = (): SocialLinkData | null => {
    try {
      this.validateWindow('get');
      const data = window.localStorage.getItem('SocialLinkStorage');

      return data ? JSON.parse(data) : null;
    } catch (error) {
      return this.handleError('get', error);
    }
  };

  static set = (data: SocialLinkData) => {
    try {
      this.validateWindow('set');
      window.localStorage.setItem('SocialLinkStorage', JSON.stringify(data));
    } catch (error) {
      this.handleError('set', error);
    }
  };

  static remove = () => {
    try {
      this.validateWindow('remove');
      window.localStorage.removeItem('SocialLinkStorage');
    } catch (error) {
      this.handleError('remove', error);
    }
  };
}
