import { ServiceType } from '../types';

const getReferrerService = (next: string): ServiceType => {
  if (!next) return 'wishket-member';
  if (next.startsWith('/magazine') || next.startsWith('%2Fmagazine'))
    return 'yozmit';
  if (next.startsWith('/superstack') || next.startsWith('%2Fsuperstack'))
    return 'superstack';

  return 'wishket';
};

export default getReferrerService;
