import { ProfileContextType } from '@/entities/profile';

export interface MarketingProps {
  profile?: ProfileContextType;
  pathName?: string;
}

export enum MarketingAgreementType {
  USE = 'use',
  SUBSCRIBE = 'subscribe',
}
