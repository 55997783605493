import { Box } from '@wishket/design-system';
import { ReactNode } from 'react';

const SECOND = 1000;
const DEFAULT_DURATION_TIME = 2 * SECOND;

export interface ToastItem {
  id: number;
  component: ReactNode;
}

type ToastListener = (toasts: ToastItem[]) => void;

export interface ToastOptions {
  duration?: number;
}

export class Toast {
  private toastStore: ToastItem[] = [];
  private listeners: ToastListener[] = [];

  create(component: ReactNode, options: ToastOptions = {}): number {
    const { duration = DEFAULT_DURATION_TIME } = options;
    const id = Date.now() + Math.random();

    const toastItem: ToastItem = {
      id,
      component: <Box className="animate-enter">{component}</Box>,
    };

    this.toastStore.push(toastItem);
    this.notify();

    if (duration > 0) {
      setTimeout(() => {
        this.remove(id);
      }, duration);
    }

    return id;
  }

  remove(id: number): void {
    this.toastStore = this.toastStore.filter((t) => t.id !== id);
    this.notify();
  }

  subscribe(listener: ToastListener): () => void {
    this.listeners.push(listener);

    listener([...this.toastStore]);

    return () => {
      this.listeners = this.listeners.filter((current) => current !== listener);
    };
  }

  private notify() {
    this.listeners.forEach((listener) => listener([...this.toastStore]));
  }
}

// 싱글톤 인스턴스
export const toast = new Toast();
