'use client';

import { SnackBar, MessageType } from '@wishket/design-system';

import { toast } from '@/shared/utils';

interface NotifyToastProps {
  message: string;
  type?: MessageType;
}

const notifyToast = ({ type = 'progress', message }: NotifyToastProps) => {
  toast.create(<SnackBar type={type} message={message} />);
};

export default notifyToast;
