type ModalType = 'notFull' | 'upToTablet';

const getScrollbarStyle = ({
  modalType = 'upToTablet',
}: { modalType?: ModalType } = {}) => {
  if (typeof window === 'undefined') return '';
  const isIos =
    /ipad|iphone/i.test(navigator.userAgent) ||
    (navigator.maxTouchPoints > 1 && /Macintosh/i.test(navigator.userAgent));

  if (modalType === 'notFull') {
    return isIos ? 'px-8' : 'scrollbar-list pl-8 pr-4';
  }

  if (modalType === 'upToTablet') {
    return isIos
      ? 'px-5'
      : 'scrollbar-list desktop:pl-8 desktop:pr-4 pl-5 pr-1';
  }

  return '';
};

export default getScrollbarStyle;
