const handleEmailValidation = (value: string) => {
  if (value === '')
    return { valid: false, errorMessage: '이메일을 입력해 주세요.' };

  const emailRegExp =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = emailRegExp.test(value);

  return {
    valid: isValid,
    errorMessage: isValid ? '' : '이메일 형식이 올바르지 않습니다.',
  };
};

const handleIdValidation = (value: string) => {
  if (value === '')
    return { valid: false, errorMessage: '아이디를 입력해 주세요.' };
  const idRegExp = /^[a-zA-Z0-9_]{6,20}$/;
  const isValid = idRegExp.test(value);

  return {
    valid: isValid,
    errorMessage: isValid
      ? ''
      : '6글자 이상의 영문자, 숫자, 특수기호(_)만 사용 가능합니다.',
  };
};

const handleEmailOrIdValidation = (value: string) => {
  if (value === '')
    return {
      valid: false,
      errorMessage: '아이디 또는 이메일을 입력해 주세요.',
    };
  if (value.length < 6)
    return { valid: false, errorMessage: '6글자 이상 입력해 주세요.' };

  const emailRegExp =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const idRegExp = /^[a-zA-Z0-9_]{6,20}$/;
  const isValid = idRegExp.test(value) || emailRegExp.test(value);
  return {
    valid: isValid,
    errorMessage: isValid
      ? ''
      : '올바른 형식의 아이디 또는 이메일을 입력해 주세요.',
  };
};

const handlePasswordValidation = (value: string) => {
  if (value === '')
    return { valid: false, errorMessage: '비밀번호를 입력해 주세요.' };
  if (value.length < 8)
    return {
      valid: false,
      errorMessage: `8글자 이상 입력해 주세요. (입력하신 내용은 ${value.length}글자입니다.)`,
    };
  const passwordRegExp = /^.{8,32}$/;
  const isValid = passwordRegExp.test(value);

  return {
    valid: isValid,
    errorMessage: isValid ? '' : `글자수를 초과하였습니다.`,
  };
};

const handlePasswordConfirmValidation = (value: string, reference: string) => {
  if (value === '')
    return {
      valid: false,
      errorMessage: '비밀번호를 한 번 더 입력해 주세요.',
    };
  const isValid = value === reference;

  return {
    valid: isValid,
    errorMessage: isValid ? '' : '동일한 비밀번호를 입력해 주세요.',
  };
};

const handlePolicyAgreementValidation = (value: boolean) => {
  return {
    valid: value,
    errorMessage: value
      ? ''
      : '위시켓 서비스 이용을 위해서 반드시 동의를 해주셔야 합니다.',
  };
};

const handleMarketingUseAgreementValidation = (value: boolean) => {
  return {
    valid: value,
  };
};

const handleMarketingSubscribeAgreementValidation = (value: boolean) => {
  return {
    valid: value,
  };
};

const validateInput = {
  email: handleEmailValidation,
  id: handleIdValidation,
  emailOrId: handleEmailOrIdValidation,
  password: handlePasswordValidation,
  passwordConfirm: handlePasswordConfirmValidation,
  policyAgreement: handlePolicyAgreementValidation,
  marketingUseAgreement: handleMarketingUseAgreementValidation,
  marketingSubscribeAgreement: handleMarketingSubscribeAgreementValidation,
};

export default validateInput;
